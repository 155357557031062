<template>
    <vue-countdown :time="time" :interval="100" v-slot="{ days, hours, minutes, seconds, milliseconds }">
      Manten tu atención en：{{ days }} días, {{ hours }} horas, {{ minutes }} minutos, {{ seconds }}.{{ Math.floor(milliseconds / 100) }} segundos.
    </vue-countdown>
  </template>
  
  <script>
  import VueCountdown from '@chenfengyuan/vue-countdown';
  
  export default {
    name: 'CountDown',
    components: {
      VueCountdown,
    },
    data() {
      const now = new Date();
      const launchDate = new Date('November 1, 2024 00:00:00');
      
      return {
        time: launchDate - now,
      };
    },
  };
  </script>
  
  <style scoped>
  </style>
  