<template>
  <div class="landing">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.landing {
  text-align: center;
  margin-top: 40px;
}
h1 {
  color: #42b983;
}
</style>
