<template>
  <div id="app">
    <img alt="Matroide AI logo" src="./assets/matroide-logo.jpg" width="200">
    <LandingPage msg="Matroide"/>
    <h3>Revolucionando la medicina con ingeniería</h3>
    <CountDown/>
  </div>
</template>

<script>
import LandingPage from './components/LandingPage.vue';
import CountDown from './components/CountDown.vue';

export default {
  name: 'App',
  components: {
    LandingPage,
    CountDown
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
h3 {
  margin: 40px 0;
}
</style>
